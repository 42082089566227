<template>
  <div
    :class="computedClass"
    ref="container"
    data-cursor-target
    data-cursor-size="lg"
  >
    <a :href="url">
      <div class="card">
        <div class="card-icon">
          <img :src="getIconUrl(icon)" alt="" />
        </div>
        <h4 class="card-title">{{ title }}</h4>
        <p class="card-text">{{ text }}</p>
        <div class="card-link">
          <img src="@/assets/images/icon-arrow-right.svg" alt="" />
        </div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: "Card",
  // data() {
  //     return {
  //         rotateX: 0,
  //         rotateY: 0,
  //     };
  // },
  props: {
    url: {
      type: String,
    },
    icon: String,
    title: String,
    text: String,
    theme: String,
  },
  computed: {
    computedClass() {
      return this.theme == "dark" ? "card-container dark" : "card-container";
    },
  },
  methods: {
    getIconUrl(name) {
      var img = require.context("@/assets/images/", false, /\.svg$/);
      return img("./" + name + ".svg");
    },
    //     animateCard(e) {
    //         let { offsetX: cursorX, offsetY: cursorY } = e,
    //             { offsetWidth: width, offsetHeight: height } = this.$refs.container;
    //         const card = this.$refs.container.querySelector('.card');
    //         const amount = 20;
    //         this.rotateX = -(width / 2 - cursorX) / amount;
    //         this.rotateY = (height / 2 - cursorY) / amount;
    //         // card.style.MozTransform = `rotateX(${this.rotateY}deg) rotateY(${this.rotateX}deg) scale(1.03)`;
    //         // card.style.WebkitTransform = `rotateX(${this.rotateY}deg) rotateY(${this.rotateX}deg) scale(1.03)`;
    //         // card.style.transform = `rotateX(${this.rotateY}deg) rotateY(${this.rotateX}deg) scale(1.03)`;

    //         if (e.type == 'mouseleave') card.style.transform = 'rotateX(0) rotateY(0) scale(1)';
    //     },
  },
};
</script>

<style lang="scss" scoped>
.card-container {
  a {
    height: 100%;
    display: block;
    cursor: pointer;

    .card {
      height: 100%;
      background-color: $white;
      box-shadow: 0 30px 40px rgb(226 230 233 / 25%);
      text-align: left;
      padding: 5rem 3rem;
      transition: 750ms cubic-bezier(0.3, 1, 0.7, 1);

      &-icon {
        width: 5rem;
        height: 5rem;
        margin-bottom: 2.5rem;
      }

      &-title {
        font-size: 1.625rem;
        color: $secondary-color;
        font-weight: 600;
        text-transform: capitalize;
        margin-bottom: 1rem;
      }

      &-text {
        font-size: 1.25rem;
        color: lighten($secondary-color, 20%);
        line-height: 1.5;
        margin-bottom: 2rem;
      }

      &-link {
        width: 24px;
      }

      &-icon,
      &-title,
      &-text,
      &-link {
        transition: 750ms cubic-bezier(0.3, 1, 0.7, 1);
      }

      &:hover {
        background-color: $primary-color;

        .card-icon,
        .card-link {
          filter: brightness(20);
        }

        .card-icon,
        .card-title,
        .card-text,
        .card-link {
          color: $white;
        }

        .card-link {
          transform: translateX(100%);
        }
      }
    }
  }

  &.dark {
    a {
      .card {
        background-color: $secondary-color;

        &-icon,
        &-link {
          filter: brightness(20);
        }

        &-icon,
        &-title,
        &-text,
        &-link {
          color: $white;
        }

        &:hover {
          background-color: $primary-color;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .card-container {
    a {
      .card {
        pointer-events: none;
        padding: 4rem 2rem;

        &-icon {
          width: 4rem;
          height: 4rem;
        }

        &-title {
          font-size: 1.25rem;
        }

        &-text {
          font-size: 0.875rem;
        }
      }
    }
  }
}
</style>
